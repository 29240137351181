export var ContentState;
(function(ContentState) {
    ContentState[ContentState["Playing"] = 0] = "Playing";
    ContentState[ContentState["Paused"] = 1] = "Paused";
    ContentState[ContentState["Stopped"] = 2] = "Stopped";
    ContentState[ContentState["Buffering"] = 3] = "Buffering";
    ContentState[ContentState["Ended"] = 4] = "Ended";
})(ContentState || (ContentState = {}));
export var CamMicState;
(function(CamMicState) {
    CamMicState[CamMicState["Disabled"] = 1] = "Disabled";
    CamMicState[CamMicState["Enabled"] = 2] = "Enabled";
    CamMicState[CamMicState["Paused"] = 3] = "Paused";
})(CamMicState || (CamMicState = {}));
export var CamMode;
(function(CamMode) {
    CamMode[CamMode["Standard"] = 0] = "Standard";
    CamMode[CamMode["Studio"] = 1] = "Studio";
})(CamMode || (CamMode = {}));
export var QualityIndicatorType;
(function(QualityIndicatorType) {
    QualityIndicatorType["PublisherNetwork"] = "publisherNetwork";
    QualityIndicatorType["PublisherCPU"] = "publisherCPU";
    QualityIndicatorType["SubscriberNetwork"] = "subscriberNetwork";
})(QualityIndicatorType || (QualityIndicatorType = {}));
