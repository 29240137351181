import { useEffect, useRef } from "react";
var useEffectOnce = function(callback, args) {
    var hasRun = useRef(false);
    useEffect(function() {
        if (!hasRun.current) {
            callback();
            hasRun.current = true;
        }
    }, args);
};
export default useEffectOnce;
