function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _objectSpread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _defineProperty(target, key, source[key]);
        });
    }
    return target;
}
import { createSlice } from "@reduxjs/toolkit";
import { RoomStates } from "./types";
import { ContentState } from "@internal/room-client";
import { setRoom, exitRoom, joinRoom, leaveRoom, updateRoomName, updateStage } from "./sharedActions";
import { normalize } from "../utils";
import { init as initUser } from "../user";
import { updateChannelMembers, updateChatUser } from "../messages/sharedActions";
import { ChannelType } from "@internal/text-chat/types";
export var initialState = {
    instance: null,
    previous: null,
    settings: {},
    participant: null,
    roomType: null,
    dataLoaded: false,
    activeSpeakerID: "",
    connected: false,
    connecting: true,
    reconnecting: false,
    contentState: {},
    contentStreamMetadataState: {},
    creating: false,
    creatorID: "",
    downVotes: {},
    id: "",
    joined: false,
    lastContentStream: {},
    losers: {},
    name: "",
    participantID: "",
    participants: {
        byId: {},
        allIds: []
    },
    userParticipants: {},
    members: {
        byId: {},
        allIds: []
    },
    activeMembers: {
        byId: {},
        allIds: []
    },
    qualityIndicatorState: {},
    state: RoomStates.Loading,
    upVotes: {},
    urlName: null,
    winners: {},
    voteState: {},
    voteStreakState: {},
    stage: {
        members: {},
        pendingInvites: {},
        pendingRequests: {}
    },
    muted: {},
    maxSubs: null,
    subsQueueSize: null,
    previewTimer: -1
};
var getRoomState = function(currentState, newContentState) {
    switch(newContentState){
        case ContentState.Playing:
        case ContentState.Paused:
            return null;
        case ContentState.Stopped:
            return RoomStates.VideoPending;
        default:
            return currentState;
    }
};
export var extraReducers = function(builder) {
    builder.addCase(initUser, function(state, action) {
        if (!state.joined) {
            return;
        }
        if (!state.members.byId[action.payload.id]) {
            state.members.allIds.push(action.payload.id);
        }
        state.members.byId[action.payload.id] = action.payload;
    }).addCase(updateRoomName, function(state, action) {
        var _payload = action.payload, roomID = _payload.roomID, name = _payload.name;
        if (roomID === state.id) {
            state.name = name;
            state.instance.name = name;
        }
    }).addCase(setRoom, function(state, action) {
        var _payload = action.payload, id = _payload.id, settings = _payload.settings, userID = _payload.userID, urlName = _payload.urlName, name = _payload.name, members = _payload.members, activeMembers = _payload.activeMembers, roomType = _payload.roomType;
        state.previous = state.instance;
        state.instance = action.payload;
        state.roomType = roomType;
        state.settings = settings;
        state.creating = false;
        state.id = id;
        state.creatorID = userID;
        state.urlName = urlName;
        state.name = name;
        state.members = normalize(members);
        state.activeMembers = normalize(activeMembers);
    }).addCase(updateStage, function(state, action) {
        state.stage = action.payload;
    }).addCase(updateChannelMembers, function(state, action) {
        var _payload = action.payload, type = _payload.type, members = _payload.members;
        if (type === ChannelType.Room) {
            members.forEach(function(member) {
                var user = member.user;
                updateChatUserHelper(state, user);
            });
        }
    }).addCase(updateChatUser, function(state, action) {
        var _payload = action.payload, type = _payload.type, user = _payload.user;
        if (type === ChannelType.Room) {
            updateChatUserHelper(state, user);
        }
    }).addCase(joinRoom, function(state) {
        state.joined = true;
    }).addCase(leaveRoom, function(state) {
        state.joined = false;
        state.guestName = null;
    }).addCase(exitRoom, function(state) {
        return _objectSpread({}, initialState, {
            previous: state.instance
        });
    });
    return builder;
};
export var roomSlice = createSlice({
    name: "room",
    initialState: initialState,
    reducers: {
        createRoom: function createRoom(state) {
            state.creating = true;
        },
        cancelCreateRoom: function cancelCreateRoom(state) {
            state.creating = false;
        },
        setID: function setID(state, action) {
            state.id = action.payload;
        },
        setParticipantID: function setParticipantID(state, action) {
            state.participantID = action.payload;
        },
        setConnecting: function setConnecting(state) {
            state.connecting = true;
        },
        setReconnecting: function setReconnecting(state) {
            state.reconnecting = true;
        },
        setConnected: function setConnected(state, action) {
            state.connected = action.payload;
            state.connecting = false;
            state.reconnecting = false;
        },
        setState: function setState(state, action) {
            state.state = action.payload;
        },
        setRoomState: function setRoomState(state, action) {
            var ref, ref1;
            var userID = (ref = action.payload.participants[state.participantID]) === null || ref === void 0 ? void 0 : ref.userID;
            return _objectSpread({}, state, action.payload, {
                participant: action.payload.participants[state.participantID],
                dataLoaded: true,
                participants: {
                    // Sort by connect time asc
                    allIds: Object.keys(action.payload.participants).sort(function(p1, p2) {
                        return action.payload.participants[p1].connectedAt - action.payload.participants[p2].connectedAt;
                    }),
                    byId: action.payload.participants
                },
                userParticipants: action.payload.userParticipantIDs,
                state: getRoomState(state.state, action.payload.contentState),
                previewTimer: (ref1 = action.payload.participants[state.participantID]) === null || ref1 === void 0 ? void 0 : ref1.previewTimer
            });
        },
        setSubsQueueSize: function setSubsQueueSize(state, action) {
            state.subsQueueSize = action.payload;
        },
        setActiveSpeakerID: function setActiveSpeakerID(state, action) {
            state.activeSpeakerID = action.payload;
        },
        updateSettings: function updateSettings(state, action) {
            state.settings = action.payload;
        },
        updateParticipant: function updateParticipant(state, action) {
            updateParticipantHelper(state, action.payload);
        },
        updateParticipants: function updateParticipants(state, action) {
            action.payload.forEach(function(participant) {
                return updateParticipantHelper(state, participant);
            });
        },
        removeParticipant: function removeParticipant(state, action) {
            var current = state.participants.byId[action.payload];
            state.participants.allIds = state.participants.allIds.filter(function(id) {
                return id !== action.payload;
            });
            // Remove member references if this was the only active participant
            // for the corresponding user
            if (current && !current.dupeUser && !current.ghost && state.userParticipants[current.userID] === action.payload) {
                delete state.userParticipants[current.userID];
                delete state.activeMembers.byId[current.userID];
                state.activeMembers.allIds = state.activeMembers.allIds.filter(function(id) {
                    return id !== current.userID;
                });
            }
            delete state.participants.byId[action.payload];
        },
        updateContentState: function updateContentState(state, action) {
            state.contentState = action.payload;
            state.state = getRoomState(state.state, action.payload);
        },
        updateContentStreamMetadata: function updateContentStreamMetadata(state, action) {
            state.contentStreamMetadataState = action.payload;
            var metadata = action.payload;
            if (!metadata.id) {
                state.lastContentStream.stoppedOn = state.lastContentStream.stoppedOn || new Date().toISOString();
                return;
            }
            state.lastContentStream = {
                id: metadata.id,
                contentName: metadata.name,
                contentPlatform: metadata.platform,
                contentURL: metadata.url,
                thumbnailURL: metadata.thumbnailURL,
                width: metadata.width,
                height: metadata.height,
                streamedOn: "",
                stoppedOn: ""
            };
        },
        updateVoteStreakState: function updateVoteStreakState(state, action) {
            var _payload = action.payload, winners = _payload.winners, losers = _payload.losers;
            state.winners = winners;
            state.losers = losers;
        },
        updateVoteState: function updateVoteState(state, action) {
            var _payload = action.payload, upVotes = _payload.upVotes, downVotes = _payload.downVotes;
            state.upVotes = upVotes;
            state.downVotes = downVotes;
        },
        setLastContentStream: function setLastContentStream(state, action) {
            state.lastContentStream = action.payload;
        },
        updateMuted: function updateMuted(state, action) {
            state.muted = action.payload;
        },
        updateQualityIndicator: function updateQualityIndicator(state, action) {
            var _payload = action.payload, indicatorType = _payload.indicatorType, active = _payload.active;
            state.qualityIndicatorState[indicatorType] = active;
        },
        updateUser: function updateUser(state, action) {
            state.members.byId[action.payload.id] = action.payload;
        },
        setWsURL: function setWsURL(state, action) {
            if (state.instance) {
                state.instance.roomServerWSURL = action.payload;
            }
        },
        setPreviewTimer: function setPreviewTimer(state, action) {
            var ref;
            var userID = (ref = state.participant) === null || ref === void 0 ? void 0 : ref.userID;
            state.previewTimer = action.payload;
        },
        setFlair: function setFlair(state, action) {
            if (state.participant) {
                state.participant.flair = action.payload;
            }
        }
    },
    extraReducers: extraReducers
});
var updateParticipantHelper = function(state, participant) {
    var id = participant.id, userID = participant.userID, name = participant.name, avatarURL = participant.avatarURL, dupeUser = participant.dupeUser, ghost = participant.ghost;
    if (dupeUser || ghost) {
        return;
    }
    // Update local participant
    if (id === state.participantID) {
        state.participant = _objectSpread({}, state.participant || {}, participant);
        state.previewTimer = participant.previewTimer;
    }
    // Add to room participants
    if (!state.participants.byId[id]) {
        state.participants.allIds.push(id);
    }
    state.participants.byId[id] = _objectSpread({}, state.participants.byId[id] || {}, participant);
    // Add user-participant mapping
    if (userID) {
        state.userParticipants[userID] = id;
    }
    // Add/update permanent room member & active member entries
    if (userID) {
        var current = state.members.byId[userID];
        var user = _objectSpread({}, current || {}, {
            id: userID,
            name: name,
            avatar: {
                url: avatarURL
            }
        });
        state.members.byId[userID] = user;
        if (!current) {
            state.members.allIds.push(userID);
        }
        var currentActive = state.activeMembers.byId[userID];
        state.activeMembers.byId[userID] = user;
        if (!currentActive) {
            state.activeMembers.allIds.push(userID);
        }
    }
};
var updateChatUserHelper = function(state, user) {
    var participantId = state.userParticipants[user.id];
    if (participantId && state.participants.byId[participantId]) {
        state.participants.byId[participantId].flair = user.flair ? user.flair[state.id] : null;
    }
    if (participantId && participantId === state.participantID && state.participant) {
        state.participant.flair = user.flair ? user.flair[state.id] : null;
    }
    if (user.id in state.members.byId) {
        state.members.byId[user.id].flair = user.flair ? user.flair[state.id] : null;
    }
};
var _actions = roomSlice.actions;
export var createRoom = _actions.createRoom, cancelCreateRoom = _actions.cancelCreateRoom, setReconnecting = _actions.setReconnecting, setID = _actions.setID, setParticipantID = _actions.setParticipantID, setActiveSpeakerID = _actions.setActiveSpeakerID, setConnected = _actions.setConnected, setRoomState = _actions.setRoomState, setSubsQueueSize = _actions.setSubsQueueSize, setState = _actions.setState, updateSettings = _actions.updateSettings, updateParticipant = _actions.updateParticipant, updateParticipants = _actions.updateParticipants, removeParticipant = _actions.removeParticipant, updateContentState = _actions.updateContentState, updateContentStreamMetadata = _actions.updateContentStreamMetadata, updateVoteStreakState = _actions.updateVoteStreakState, updateVoteState = _actions.updateVoteState, setLastContentStream = _actions.setLastContentStream, updateMuted = _actions.updateMuted, updateQualityIndicator = _actions.updateQualityIndicator, updateUser = _actions.updateUser, setWsURL = _actions.setWsURL, setPreviewTimer = _actions.setPreviewTimer, setFlair = _actions.setFlair;
export default roomSlice.reducer;
