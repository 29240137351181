export var PermissionType;
(function(PermissionType) {
    PermissionType[PermissionType["Speak"] = 1] = "Speak";
    PermissionType[PermissionType["Stream"] = 2] = "Stream";
    PermissionType[PermissionType["StageInvite"] = 3] = "StageInvite";
    PermissionType[PermissionType["Ban"] = 4] = "Ban";
    PermissionType[PermissionType["Mute"] = 5] = "Mute";
    PermissionType[PermissionType["Settings"] = 6] = "Settings";
    PermissionType[PermissionType["ChangeName"] = 7] = "ChangeName";
    PermissionType[PermissionType["RoleGranter"] = 8] = "RoleGranter";
})(PermissionType || (PermissionType = {}));
export var NotificationType;
(function(NotificationType) {
    NotificationType[NotificationType["StreamStart"] = 1] = "StreamStart";
    NotificationType[NotificationType["StageStart"] = 2] = "StageStart";
    NotificationType[NotificationType["ChatMention"] = 3] = "ChatMention";
})(NotificationType || (NotificationType = {}));
export var NotificationChannelType;
(function(NotificationChannelType) {
    NotificationChannelType[NotificationChannelType["Push"] = 1] = "Push";
    NotificationChannelType[NotificationChannelType["Email"] = 2] = "Email";
})(NotificationChannelType || (NotificationChannelType = {}));
