function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _asyncToGenerator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
import regeneratorRuntime from "/vercel/path0/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import { requestGET, requestPUT, requestPOST, requestDELETE } from "./utils";
export var tvAuthSubscriptionsGET = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(jwt, origin) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    return _ctx.abrupt("return", requestGET({
                        url: "".concat(origin, "/tv-auth/subscriptions"),
                        jwt: jwt
                    }));
                case 1:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function tvAuthSubscriptionsGET(jwt, origin) {
        return _ref.apply(this, arguments);
    };
}();
export var tvAuthAttemptPUT = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(jwt, data, origin) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    return _ctx.abrupt("return", requestPUT({
                        url: "".concat(origin, "/tv-auth/attempt"),
                        jwt: jwt,
                        data: data
                    }));
                case 1:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function tvAuthAttemptPUT(jwt, data, origin) {
        return _ref.apply(this, arguments);
    };
}();
export var tvAuthAttemptPOST = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(jwt, data, origin) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    return _ctx.abrupt("return", requestPOST({
                        url: "".concat(origin, "/tv-auth/attempt"),
                        jwt: jwt,
                        data: data
                    }));
                case 1:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function tvAuthAttemptPOST(jwt, data, origin) {
        return _ref.apply(this, arguments);
    };
}();
export var tvAuthSubscriptionPUT = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(jwt, data, origin) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    return _ctx.abrupt("return", requestPUT({
                        url: "".concat(origin, "/tv-auth/subscription"),
                        jwt: jwt,
                        data: data
                    }));
                case 1:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function tvAuthSubscriptionPUT(jwt, data, origin) {
        return _ref.apply(this, arguments);
    };
}();
export var tvAuthSubscriptionPOST = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(jwt, data, origin) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    requestPOST({
                        url: "".concat(origin, "/tv-auth/subscription"),
                        jwt: jwt,
                        data: data
                    });
                case 1:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function tvAuthSubscriptionPOST(jwt, data, origin) {
        return _ref.apply(this, arguments);
    };
}();
export var tvAuthWhitelistPUT = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(modSecret, userID, origin) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    return _ctx.abrupt("return", requestPUT({
                        url: "".concat(origin, "/mod/tv-auth/whitelist"),
                        secret: modSecret,
                        data: {
                            userID: userID
                        }
                    }));
                case 1:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function tvAuthWhitelistPUT(modSecret, userID, origin) {
        return _ref.apply(this, arguments);
    };
}();
export var tvAuthSubscriptionDELETE = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(jwt, data, origin) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    return _ctx.abrupt("return", requestDELETE({
                        url: "".concat(origin, "/tv-auth/subscription"),
                        jwt: jwt,
                        data: data
                    }));
                case 1:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function tvAuthSubscriptionDELETE(jwt, data, origin) {
        return _ref.apply(this, arguments);
    };
}();
