export var UserFlag;
(function(UserFlag) {
    UserFlag["PmfSurveyResponded"] = "pmfSurveyResponded";
    UserFlag["PmfSurveyDismissed"] = "pmfSurveyDismissed";
    UserFlag["DownloadPromptAccepted"] = "downloadPromptAccepted";
    UserFlag["DownloadPromptDismissed"] = "downloadPromptDismissed";
    UserFlag["IphoneBetaPromptAccepted"] = "iphoneBetaPromptAccepted";
    UserFlag["IphoneBetaPromptDismissed"] = "iphoneBetaPromptDismissed";
    UserFlag["IphoneAppInstalled"] = "iphoneAppInstalled";
    UserFlag["StreamingServiceSurveyDismissed"] = "streamingServiceSurveyDismissed";
    UserFlag["StreamingServiceSurveySubmitted"] = "streamingServiceSurveySubmitted";
    UserFlag["TipGiphyDismissed"] = "tipGiphyDismissed";
    UserFlag["TipHelpDismissed"] = "tipHelpDismissed";
    UserFlag["TipVideoChatDrag"] = "tipVideoChatDrag";
    UserFlag["TipRoomAudioBalance"] = "tipRoomAudioBalance";
    UserFlag["TipMobileWebAudioBalance"] = "tipMobileWebAudioBalance";
    UserFlag["TipRoomFlair"] = "tipRoomFlair";
})(UserFlag || (UserFlag = {}));
