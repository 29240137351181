function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _asyncToGenerator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
import regeneratorRuntime from "/vercel/path0/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import { requestGET, requestPUT, requestPOST, requestDELETE } from "./utils";
export var roomGET = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(params, origin) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    return _ctx.abrupt("return", requestGET({
                        url: "".concat(origin, "/room"),
                        params: params
                    }));
                case 1:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function roomGET(params, origin) {
        return _ref.apply(this, arguments);
    };
}();
export var roomPUT = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(jwt, data, origin) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    return _ctx.abrupt("return", requestPUT({
                        url: "".concat(origin, "/room"),
                        jwt: jwt,
                        data: data
                    }));
                case 1:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function roomPUT(jwt, data, origin) {
        return _ref.apply(this, arguments);
    };
}();
export var roomDELETE = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(jwt, data, origin) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    return _ctx.abrupt("return", requestDELETE({
                        url: "".concat(origin, "/room"),
                        jwt: jwt,
                        data: data
                    }));
                case 1:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function roomDELETE(jwt, data, origin) {
        return _ref.apply(this, arguments);
    };
}();
export var roomsGET = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(jwt, params, origin) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    return _ctx.abrupt("return", requestGET({
                        url: "".concat(origin, "/rooms"),
                        jwt: jwt,
                        params: params
                    }));
                case 1:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function roomsGET(jwt, params, origin) {
        return _ref.apply(this, arguments);
    };
}();
export var roomServerPOST = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(data, origin) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    return _ctx.abrupt("return", requestPOST({
                        url: "".concat(origin, "/room/server"),
                        data: data
                    }));
                case 1:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function roomServerPOST(data, origin) {
        return _ref.apply(this, arguments);
    };
}();
export var roomConfigGET = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(params, origin) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    return _ctx.abrupt("return", requestGET({
                        url: "".concat(origin, "/room/config"),
                        params: params
                    }));
                case 1:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function roomConfigGET(params, origin) {
        return _ref.apply(this, arguments);
    };
}();
export var roomSettingsPOST = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(jwt, data, origin) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    return _ctx.abrupt("return", requestPOST({
                        url: "".concat(origin, "/room/settings"),
                        jwt: jwt,
                        data: data
                    }));
                case 1:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function roomSettingsPOST(jwt, data, origin) {
        return _ref.apply(this, arguments);
    };
}();
export var roomModeratorPOST = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(jwt, data, origin) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    return _ctx.abrupt("return", requestPOST({
                        url: "".concat(origin, "/room/moderator"),
                        jwt: jwt,
                        data: data
                    }));
                case 1:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function roomModeratorPOST(jwt, data, origin) {
        return _ref.apply(this, arguments);
    };
}();
export var roomArchivePOST = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(jwt, data, origin) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    return _ctx.abrupt("return", requestPOST({
                        url: "".concat(origin, "/room/archive"),
                        jwt: jwt,
                        data: data
                    }));
                case 1:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function roomArchivePOST(jwt, data, origin) {
        return _ref.apply(this, arguments);
    };
}();
export var roomNamePOST = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(jwt, data, origin) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    return _ctx.abrupt("return", requestPOST({
                        url: "".concat(origin, "/room/name"),
                        jwt: jwt,
                        data: data
                    }));
                case 1:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function roomNamePOST(jwt, data, origin) {
        return _ref.apply(this, arguments);
    };
}();
export var roomSessionGET = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(jwt, params, origin) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    return _ctx.abrupt("return", requestGET({
                        url: "".concat(origin, "/room/session"),
                        jwt: jwt,
                        params: params
                    }));
                case 1:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function roomSessionGET(jwt, params, origin) {
        return _ref.apply(this, arguments);
    };
}();
export var roomSessionPUT = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(jwt, data, origin) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    return _ctx.abrupt("return", requestPUT({
                        url: "".concat(origin, "/room/session"),
                        jwt: jwt,
                        data: data
                    }));
                case 1:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function roomSessionPUT(jwt, data, origin) {
        return _ref.apply(this, arguments);
    };
}();
export var roomSessionPOST = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(jwt, data, origin) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    return _ctx.abrupt("return", requestPOST({
                        url: "".concat(origin, "/room/session"),
                        jwt: jwt,
                        data: data
                    }));
                case 1:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function roomSessionPOST(jwt, data, origin) {
        return _ref.apply(this, arguments);
    };
}();
export var roomSessionDELETE = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(jwt, data, origin) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    return _ctx.abrupt("return", requestDELETE({
                        url: "".concat(origin, "/room/session"),
                        jwt: jwt,
                        data: data
                    }));
                case 1:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function roomSessionDELETE(jwt, data, origin) {
        return _ref.apply(this, arguments);
    };
}();
export var roomSessionsGET = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(jwt, params, origin) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    return _ctx.abrupt("return", requestGET({
                        url: "".concat(origin, "/room/sessions"),
                        jwt: jwt,
                        params: params
                    }));
                case 1:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function roomSessionsGET(jwt, params, origin) {
        return _ref.apply(this, arguments);
    };
}();
export var roomsSessionsGET = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(jwt, params, origin) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    return _ctx.abrupt("return", requestGET({
                        url: "".concat(origin, "/rooms/sessions"),
                        jwt: jwt,
                        params: params
                    }));
                case 1:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function roomsSessionsGET(jwt, params, origin) {
        return _ref.apply(this, arguments);
    };
}();
