export var TVAuthStatus;
(function(TVAuthStatus) {
    TVAuthStatus[TVAuthStatus["Success"] = 1] = "Success";
    TVAuthStatus[TVAuthStatus["Fail"] = 2] = "Fail";
    TVAuthStatus[TVAuthStatus["Invalid"] = 3] = "Invalid";
    TVAuthStatus[TVAuthStatus["UsernameInvalid"] = 4] = "UsernameInvalid";
    TVAuthStatus[TVAuthStatus["PasswordInvalid"] = 5] = "PasswordInvalid";
    TVAuthStatus[TVAuthStatus["PortalTimeout"] = 6] = "PortalTimeout";
    TVAuthStatus[TVAuthStatus["PlatformError"] = 7] = "PlatformError";
})(TVAuthStatus || (TVAuthStatus = {}));
