export var RoomEntryType;
(function(RoomEntryType) {
    RoomEntryType["CreateRoom"] = "create";
    RoomEntryType["DeepLink"] = "deep link";
    RoomEntryType["RoomList"] = "room list";
    RoomEntryType["Direct"] = "direct";
    RoomEntryType["Notification"] = "notification";
    RoomEntryType["Paste"] = "paste";
    RoomEntryType["DupeDisconnect"] = "dupe disconnect";
})(RoomEntryType || (RoomEntryType = {}));
export var ShadowEventType;
(function(ShadowEventType) {
    ShadowEventType["Identify"] = "i";
    ShadowEventType["Track"] = "t";
    ShadowEventType["Page"] = "p";
})(ShadowEventType || (ShadowEventType = {}));
