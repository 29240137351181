import { useEffect, useState } from "react";
var useShowWhenVisible = function(param) {
    var visible = param.visible, duration = param.duration;
    var ref = useState(false), isShowing = ref[0], setIsShowing = ref[1];
    useEffect(function() {
        if (visible) {
            setIsShowing(true);
        } else {
            var timeout = setTimeout(function() {
                setIsShowing(false);
            }, duration);
            return function() {
                clearTimeout(timeout);
            };
        }
    }, [
        visible,
        duration
    ]);
    return isShowing;
};
export default useShowWhenVisible;
