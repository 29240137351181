function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
export var ScheduledEventType;
(function(ScheduledEventType) {
    ScheduledEventType["Game"] = "game";
    ScheduledEventType["Generic"] = "generic";
})(ScheduledEventType || (ScheduledEventType = {}));
export var SportsLeague;
(function(SportsLeague) {
    SportsLeague[SportsLeague["MLB"] = 1] = "MLB";
    SportsLeague[SportsLeague["NFL"] = 2] = "NFL";
    SportsLeague[SportsLeague["CFB"] = 3] = "CFB";
    SportsLeague[SportsLeague["NBA"] = 4] = "NBA";
    SportsLeague[SportsLeague["MLS"] = 5] = "MLS";
    SportsLeague[SportsLeague["EPL"] = 6] = "EPL";
    SportsLeague[SportsLeague["NHL"] = 7] = "NHL";
    SportsLeague[SportsLeague["WNBA"] = 8] = "WNBA";
})(SportsLeague || (SportsLeague = {}));
export var leagues = [
    SportsLeague.MLB,
    SportsLeague.NFL,
    SportsLeague.CFB,
    SportsLeague.NBA,
    SportsLeague.MLS,
    SportsLeague.EPL,
    SportsLeague.NHL,
    SportsLeague.WNBA, 
];
var _obj;
export var sportsLeagueNames = (_obj = {}, _defineProperty(_obj, SportsLeague.MLB, "MLB"), _defineProperty(_obj, SportsLeague.NFL, "NFL"), _defineProperty(_obj, SportsLeague.CFB, "College Football"), _defineProperty(_obj, SportsLeague.NBA, "NBA"), _defineProperty(_obj, SportsLeague.MLS, "MLS"), _defineProperty(_obj, SportsLeague.EPL, "EPL"), _defineProperty(_obj, SportsLeague.NHL, "NHL"), _defineProperty(_obj, SportsLeague.WNBA, "WNBA"), _obj);
